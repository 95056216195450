import InputControl from '../../components/common/formik-chakra/InputControl'
import { AutoWidthCenter } from '../../components/layout/AutoWidthCenter'
import { Box, Button, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import { ForgotPasswordFormValues } from '../../models/Password'
import { Form, Formik } from 'formik'
import { useForgotPasswordMutation } from '../../api/calls/user/PasswordQueries'
import { useState } from 'react'

export default function ForgotPassword() {
  const [formValues, setFormValues] = useState(new ForgotPasswordFormValues());
  const [submitting, setSubmitting] = useState(false);
  const [completed, setCompleted] = useState(false);
  const handleForgotPassword = useForgotPasswordMutation();

  const handleSubmit = async (values: ForgotPasswordFormValues) => {
    setSubmitting(true);
    handleForgotPassword.mutate(values, {
      onSuccess() {
        setSubmitting(false);
        setCompleted(true);
      }
    });
  }

  return (
    <AutoWidthCenter>
      <Flex align="center" justify="center">
        {completed ? (
          <VStack>
            <Heading as="h1">Forgot password</Heading>
            <Text>Thank you. A link has now been sent by e-mail. Open this link to change your password.</Text>
          </VStack>
        ) : (
          <Formik initialValues={formValues}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Box p="10" m="6" rounded="lg" maxW="80%" w="80%">
                <Heading as="h1">Forgot password</Heading>
                <Text>Enter your email in order to receive a password-reset email</Text>
                <Form onSubmit={formik.handleSubmit}>
                  <Flex justify="flex-end">
                    <a className='underline-h' href='/'>Login</a>
                  </Flex>
                  <InputControl name="userName" label="Email" />
                  <Box width="100%" pt="3">
                    <Button float="right" type="submit" disabled={submitting}>Request password reset</Button>
                  </Box>
                </Form>
              </Box>
            )}
          </Formik>
        )}
      </Flex>
    </AutoWidthCenter>
  );
}