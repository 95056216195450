import { apiClient } from '../../base/ApiClients'
import { IWebsiteGroupedStats } from '../../../models/website/IWebsite'

export const GetWebsitesStatisticsQuery = async (days: number) => {
  const { data } = await apiClient.get<IWebsiteGroupedStats[]>(`website/stats/${days}`);
  return data;
}

export const GetCustomerWebsitesStatisticsQuery = async (days: number) => {
  const { data } = await apiClient.get<IWebsiteGroupedStats[]>(`website/stats/${days}/customer`);
  return data;
}