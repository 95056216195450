import { apiClient } from '../../base/ApiClients'
import { CustomerFormValues, CustomerFormValuesWithId, CustomerSimpleFormValuesWithId, ICustomer, ISimpleCustomer, IWebsitesForCustomer, WebsitesForCustomerFormValuesWithId } from '../../../models/Customer'
import { useMutation } from 'react-query'

export const GetProfile = async () => {
  const { data } = await apiClient.get<ISimpleCustomer>("customer/me");
  return data;
}

const EditProfile = async (values: CustomerSimpleFormValuesWithId) => {
  return await apiClient.put(`customer/me`, values.values)
}

export const useEditProfileMutation = () => useMutation(EditProfile, {});

export const GetCustomers = async () => {
  const { data } = await apiClient.get<ICustomer[]>("customer");
  return data;
}

export const GetCustomer = async (id: any) => {
  const { data } = await apiClient.get<ICustomer>(`customer/${id}`);
  return data;
}

const CreateCustomer = async (values: CustomerFormValues) => {
  return await apiClient.post("customer", values);
}

export const useCreateCustomerMutation = () => useMutation(CreateCustomer, {});

const EditCustomer = async (values: CustomerFormValuesWithId) => {
  return await apiClient.put(`customer/${values.id}`, values.values);
}

export const useEditCustomerMutation = () => useMutation(EditCustomer, {});

const DeleteCustomer = async (id: string) => {
  return await apiClient.delete(`customer/${id}`);
}

export const useDeleteCustomerMutation = () => useMutation(DeleteCustomer, {});

export const GetWebsitesForCustomerQuery = async (id: any) => {
  const { data } = await apiClient.get<IWebsitesForCustomer>(`customer/${id}/websites`);
  return data;
}

const UpdateWebsitesForCustomer = async (values: WebsitesForCustomerFormValuesWithId) => {
  return await apiClient.put(`customer/${values.id}/websites`, values.values);
}

export const useUpdateWebsitesForCustomerMutation = () => useMutation(UpdateWebsitesForCustomer, {});