import Admins from '../../pages/secure/dashboard/admin/Admins'
import CreateAdmin from '../../pages/secure/dashboard/admin/CreateAdmin'
import EditAdmin from '../../pages/secure/dashboard/admin/EditAdmin'
import { ERoles } from '../../enums/ERoles'
import { IRoute } from '../../models/routes/IRoute'

export const AdminRoutes: IRoute[] = [
  {
    element: <Admins />,
    path: "admins",
    permission: [ERoles.ADMIN],
    title: "Admins",
    showInMenu: true,
  },
  {
    element: <EditAdmin />,
    path: "admins/edit/:id",
    permission: [ERoles.ADMIN],
    title: "Edit Admin",
  },
  {
    element: <CreateAdmin />,
    path: "admins/new",
    permission: [ERoles.ADMIN],
    title: "New Admin",
  },
]