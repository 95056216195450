import CustomerNavbar from '../../components/layout/navbar/CustomerNavbar'
import { AuthContext } from '../../hooks/useAuth'
import { Navigate, Outlet } from 'react-router-dom'
import { SessionGuard } from '../../components/common/guards/SessionGuard'
import { useContext } from 'react'

export default function CustomerLayout() {
  const tokenContext = useContext(AuthContext);

  if (tokenContext.isInCustomerRole()) {
    return (
      <>
        <SessionGuard />
        <CustomerNavbar />
        <Outlet />
      </>
    )
  } else {
    return <Navigate to="/" replace />
  }
}