export interface IAdmin {
  id: string;
  email: string;
  firstName: string;
  userName: string;
  lastNamePrefix?: string;
  lastName?: boolean;
}

export class AdminFormValues {
  id?: string | undefined;
  email: string = '';
  userName: string = '';
  firstName: string = '';
  lastName: string = '';
  lastNamePrefix: string = '';

  constructor(init?: IAdmin) {
    Object.assign(this, init);
  }
}

export type AdminFormValuesWithId = {
  id: string;
  values: AdminFormValues;
}