import { AdminRoutes } from './dashboardSubRoutes/AdminRoutes'
import { ContactRoutes } from './dashboardSubRoutes/ContactRoutes'
import { CustomerRoutes } from './dashboardSubRoutes/CustomerRoutes'
import { GeneralRoutes } from './dashboardSubRoutes/GeneralRoutes'
import { IRoute } from '../models/routes/IRoute'
import { WebsiteRoutes } from './dashboardSubRoutes/WebsiteRoutes'

const dashboardRoutesConfig: IRoute[] = [
  ...GeneralRoutes,
  ...AdminRoutes,
  ...CustomerRoutes,
  ...WebsiteRoutes,
  ...ContactRoutes,
];

export default dashboardRoutesConfig;