import WebsiteList from '../../../../components/secure/website/WebsiteList'
import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Button, Heading, HStack } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

export default function Websites() {
  return (
    <AutoWidthCenter>
      <HStack pb="5" mt="5" justifyContent="space-between">
        <Heading as="h1">Websites</Heading>
        <Button as={NavLink} to="new">New website</Button>
      </HStack>
      <WebsiteList />
    </AutoWidthCenter>
  );
}