import * as Yup from 'yup'

export const CustomerValidationSchema = Yup.object({
  email: Yup.string().required(),
  userName: Yup.string().required().matches(/^\S*$/, "Username may not contain spaces"),
  firstName: Yup.string().required(),
  lastName: Yup.string().optional(),
  lastNamePrefix: Yup.string().optional(),
  phone: Yup.string().optional().matches(/^[^ +\-]*$/, "Phonenumber may not contain a ' ', '+' or a '-'"),
  sendingDelay: Yup.number().min(0).required(),
})