import CustomerLayout from './globalstyles/layouts/CustomerLayout'
import customerRoutesConfig from './routes/CustomerRoutesConfig'
import dashboardRoutesConfig from './routes/DashboardRoutesConfig'
import { createBrowserHistory } from 'history'
import { DashboardLayout } from './globalstyles/layouts/DasbhoardLayout'
import { GetAllowedRoutes } from './routes/RouteUtils'
import { IRoute } from './models/routes/IRoute'
import { MainLayout } from './globalstyles/layouts/MainLayout'
import { ModalContainer } from './components/common/modal/ModalContainer'
import { ModalProvider } from './context/ModalContext'
import { NotFound } from './pages/errorPages/NotFound'
import { PublicRoutes } from './routes/publicRoutes/PublicRoutes'
import { Route, RouteObject, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { Signout } from './pages/login/Signout'

export const history = createBrowserHistory({ window });
export const routePrefixDashboard = "/dashboard";
export const routePrefixCustomer = "/customer";
export const routePrefixRedirectLogin = "/login";

export const ApplicationRouter = () => {
  const allowedDashboardRoutes = GetAllowedRoutes(dashboardRoutesConfig);
  const allowedCustomerRoutes = GetAllowedRoutes(customerRoutesConfig);

  const mapAllowedRoutes = (allowedRoutes: IRoute[]) => {
    return allowedRoutes.map((route: IRoute) => {
      const { dropdownRoutes, element, path } = route;
      return (
        // If the current route is of type dropdown, don't use it as a route.
        !dropdownRoutes?.dropdown
          ? <Route key={path} path={path} element={element} />
          : (
            // Route its childs instead
            dropdownRoutes.childRoutes?.map((childRoute: IRoute) => {
              const { element, path } = childRoute;
              return (
                <Route key={path} path={path} element={element} />
              )
            })
          )
      )
    })
  }

  return (
    <HistoryRouter history={history}>
      <ModalProvider>
        <ModalContainer />
        <Routes>
          {/* Public routes */}
          <Route element={<MainLayout />}>
            {PublicRoutes.map((route: RouteObject) => {
              return <Route key={route.path} path={route.path} element={route.element} index={route.index} />
            })}
            <Route path="*" element={<NotFound />} />
          </Route>

          {/* Dashboard routes */}
          <Route path={routePrefixDashboard} element={<DashboardLayout />}>
            {mapAllowedRoutes(allowedDashboardRoutes)}
          </Route>

          {/* Customer routes */}
          <Route path={routePrefixCustomer} element={<CustomerLayout />}>
            {mapAllowedRoutes(allowedCustomerRoutes)}
          </Route>
          <Route path='/signout' element={<Signout />} />
        </Routes>
      </ModalProvider>
    </HistoryRouter>
  )
}
