import AdminForm from '../../../../components/secure/admins/AdminForm'
import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack } from '@chakra-ui/react'
import { GetAdmin } from '../../../../api/calls/admin/AdminQueries'
import { history } from '../../../../ApplicationRouter'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

export default function EditAdmin() {
  const { id } = useParams();
  const { data } = useQuery(['admin', id], () => GetAdmin(id), { enabled: !!id });

  return (
    <AutoWidthCenter>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">Edit admin</Heading>
          <Button onClick={() => history.back()}>Back</Button>
        </HStack>
        <Divider />
        {data && <AdminForm admin={data} />}
      </Box>
    </AutoWidthCenter>
  );
}