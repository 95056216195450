import { AiOutlineDelete } from 'react-icons/ai'
import { Box, Button, ButtonGroup, Flex, HStack, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Text, useToast } from '@chakra-ui/react'
import { ErrorBody } from './ErrorBody'
import { EStatusCheck } from '../../../enums/EStatusCheck'
import { FiEdit2 } from 'react-icons/fi'
import { formatDateTime } from '../../../utils/General'
import { IWebsiteGroupedStats } from '../../../models/website/IWebsite'
import { useDeleteWebsiteMutation } from '../../../api/calls/website/WebsiteQueries'
import { useModal } from '../../../context/ModalContext'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

interface IProps {
  website: IWebsiteGroupedStats;
  amountOfDays: number;
  isForCustomer?: boolean;
}

export const WebsiteStatistic = ({ website, amountOfDays, isForCustomer = false }: IProps) => {
  const navigate = useNavigate();
  const { dispatch } = useModal();
  const toast = useToast();
  const handleDeleteWebsite = useDeleteWebsiteMutation();
  const queryClient = useQueryClient();

  const handleDelete = (id: string) => {
    if (isForCustomer) return;
    handleDeleteWebsite.mutate(id, {
      onSuccess() {
        queryClient.invalidateQueries(["website/stats/", amountOfDays])
        dispatch({ type: 'close' })
        toast({
          title: "Website Deleted",
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      },
      onError() {
        toast({
          title: "Error deleting website",
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top"
        })
      }
    })
  }

  const keys = Object.keys(website.groupedStatusChecks);
  const checks = Object.values(website.groupedStatusChecks);

  return (
    <Box marginBottom="20px">
      <HStack pb="5" justifyContent="space-between">
        <Text fontWeight="bold">{website.name} @ {website.url}</Text>
        {!isForCustomer && (
          <ButtonGroup>
            <IconButton icon={<FiEdit2 />} aria-label="" onClick={() => navigate(`websites/edit/${website.id}`)}>Edit</IconButton>
            <IconButton
              icon={<AiOutlineDelete fontSize="1.25rem" />}
              aria-label="Delete website"
              color="red"
              onClick={() => dispatch({
                type: 'open', payload: {
                  title: "Delete website",
                  bodyContent: "Are you sure you want to delete the website?",
                  footerContent:
                    <>
                      <Button mr={3} color="red" onClick={() => handleDelete(website.id)}>Delete</Button>
                      <Button onClick={() => dispatch({ type: 'close' })}>Cancel</Button>
                    </>
                }
              })}
            />
          </ButtonGroup>
        )}
      </HStack>
      <Flex flexFlow="row nowrap" justifyContent="space-between">
        {[...Array(keys.length)].map((_, i) => {
          const currentKey = keys[i]
          const currentChecks = checks[i]
          let containsData = currentChecks.length > 0;
          let containsErrors = currentChecks.filter((check) => check.status !== EStatusCheck.success)
          return (
            <Popover trigger='hover' key={i}>
              <PopoverTrigger>
                <Box
                  height="24px" width="10px"
                  _hover={{ backgroundColor: containsErrors.length > 0 ? "rgba(255, 0, 0, 0.3)" : "blue", cursor: containsErrors.length > 0 ? "pointer" : "" }}
                  backgroundColor={!containsData ? "blue" : containsErrors.length > 0 ? "red" : "blue"}
                />
              </PopoverTrigger>
              {containsErrors.length > 0 && (
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>{formatDateTime(new Date(currentKey), "i18n.Formatted.Date")}</PopoverHeader>
                  <PopoverBody>
                    {!containsData
                      ? <Text>No data</Text>
                      : <ErrorBody errorStatuses={containsErrors} />
                    }
                  </PopoverBody>
                </PopoverContent>
              )}
            </Popover>
          )
        })}
      </Flex>
    </Box>
  )
}