export class ForgotPasswordFormValues {
  userName: string = "";
}

export interface VerifyTokenDto {
  userName: string;
  token: string;
}

export class VerifyPasswordResetValues {
  userName: string = "";
  token: string = "";
  constructor(init?: VerifyTokenDto) {
    Object.assign(this, init);
  }
}

export class ResetPasswordFormValues {
  userName: string = "";
  token: string = "";
  password: string = "";
  confirmPassword: string = "";
  constructor(init?: VerifyTokenDto) {
    Object.assign(this, init);
  }
}