import { AiOutlineDelete } from 'react-icons/ai'
import { Button, Heading, IconButton, Spinner, Text, useToast } from '@chakra-ui/react'
import { DataView, IDataViewColumn } from '../../common/dataview/DataView'
import { FiEdit2 } from 'react-icons/fi'
import { GetWebsitesQuery, useDeleteWebsiteMutation } from '../../../api/calls/website/WebsiteQueries'
import { IWebsite } from '../../../models/website/IWebsite'
import { NavLink } from 'react-router-dom'
import { useModal } from '../../../context/ModalContext'
import { useQuery } from 'react-query'
import { useState } from 'react'

export default function WebsiteList() {
  const { dispatch } = useModal();
  const toast = useToast();

  const [columns] = useState<IDataViewColumn[]>([
    { headerName: "Name", field: "name" },
    { headerName: "Url", field: "url" },
    { headerName: "SMS name", field: "smsShortName" },
    { headerName: "Endoint", field: "endpoint" },
    { headerName: "Enabled", field: "enabled", cellRenderer: 'checkmark' },
    { headerName: "Actions", cellRenderer: 'actions', flex: 0.5 },
  ]);
  const handleDeleteWebsite = useDeleteWebsiteMutation();
  const { data, isLoading } = useQuery(['websites'], GetWebsitesQuery);

  const handleDelete = (id: string) => {
    handleDeleteWebsite.mutate(id, {
      onSuccess() {
        dispatch({ type: 'close' })
        toast({
          title: "Website Deleted",
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      },
      onError() {
        toast({
          title: "Error deleting website",
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top"
        })
      }
    })
  }

  const renderCellAction = (tableData: any) => {
    const website: IWebsite = tableData.data;
    return (
      <>
        <IconButton
          icon={<FiEdit2 fontSize="1.25rem" />}
          aria-label='Edit website'
          as={NavLink}
          to={`edit/${website.id}`}
        />
        <IconButton
          icon={<AiOutlineDelete fontSize="1.25rem" />}
          aria-label="Delete website"
          color="red"
          onClick={() => dispatch({
            type: 'open', payload: {
              title: "Delete website",
              bodyContent: <>
                <Text>Are you sure you want to delete the website?</Text>
              </>,
              footerContent:
                <>
                  <Button mr={3} color="red" onClick={() => handleDelete(website.id)}>Delete</Button>
                  <Button onClick={() => dispatch({ type: 'close' })}>Close</Button>
                </>
            }
          })}
        />
      </>
    )
  }

  if (isLoading) return <Spinner />
  if (!isLoading && !data) return <Heading as="h1">No websites found.</Heading>

  return (
    <DataView
      actionsRenderer={renderCellAction}
      columns={columns}
      data={data}
    />
  );
}