import { Box, Button, Divider, Heading, Text, useToast, VStack } from '@chakra-ui/react'
import { CustomerProfileValidationSchema } from './CustomerProfile.Validation'
import { CustomerSimpleFormValues, ISimpleCustomer } from '../../../models/Customer'
import { Form, Formik } from 'formik'
import { InputControl, PasswordControl } from '../../common/formik-chakra'
import { useEditProfileMutation } from '../../../api/calls/customer/CustomerQueries'
import { useEffect, useState } from 'react'

interface Props {
  customer: ISimpleCustomer;
}

export default function CustomerProfileForm({ customer }: Props) {
  const [formValues, setFormValues] = useState(new CustomerSimpleFormValues());

  const handleUpdateProfile = useEditProfileMutation();
  const toast = useToast();

  useEffect(() => {
    setFormValues(customer);
  }, [customer]);

  const handleSubmit = (values: CustomerSimpleFormValues) => {
    handleUpdateProfile.mutate({ id: customer.id, values: values }, {
      onSuccess() {
        toast({
          title: "Profile updated",
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      },
      onError() {
        toast({
          title: "Error updating profile",
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top"
        })
      }
    });
  }

  return (
    <>
      <Heading size="lg">Profile</Heading>
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={CustomerProfileValidationSchema}
      >
        {(formik) => (
          <Box p="10" m="6" rounded="lg" maxW="80%" w="80%">
            <Form onSubmit={formik.handleSubmit}>
              <VStack>
                <InputControl name="firstName" label="First name" isRequired />
                <InputControl name="lastName" label="Last name" />
                <InputControl name="lastNamePrefix" label="Last name prefix" />
                <InputControl name="phone" label="Phone number starting with country-code (no ' ' or '+' or '-')" />

                <Divider />
                <Box width="100%" p="3" borderColor="black" borderWidth="thin" >
                  <Text>Enter a new password below if you want to change your password.</Text>
                  <Text>Otherwise leave these fields empty.</Text>
                  <PasswordControl mt="2" name="password.currentPassword" label="Current password" />
                  <PasswordControl name="password.password" label="New password" />
                  <PasswordControl name="password.confirmPassword" label="Confirm new password" />
                </Box>

                <Box width="100%" pt="3">
                  <Button float="right" type="submit">Submit</Button>
                </Box>
              </VStack>
            </Form>
          </Box>
        )}
      </Formik>
    </>
  );
}