import CreateCustomer from '../../pages/secure/dashboard/customer/CreateCustomer'
import Customers from '../../pages/secure/dashboard/customer/Customers'
import EditCustomer from '../../pages/secure/dashboard/customer/EditCustomer'
import { ERoles } from '../../enums/ERoles'
import { IRoute } from '../../models/routes/IRoute'

export const CustomerRoutes: IRoute[] = [
  {
    element: <Customers />,
    path: "customers",
    permission: [ERoles.ADMIN],
    title: "Customers",
    showInMenu: true,
  },
  {
    element: <CreateCustomer />,
    path: "customers/new",
    permission: [ERoles.ADMIN],
    title: "Create customers",
  },
  {
    element: <EditCustomer />,
    path: "customers/edit/:id",
    permission: [ERoles.ADMIN],
    title: "Edit customers",
  },
]