import { ERoles } from '../../enums/ERoles'
import { Home } from '../../pages/secure/customer/Home'
import { IRoute } from '../../models/routes/IRoute'

export const GeneralRoutes: IRoute[] = [
  {
    element: <Home />,
    path: "",
    permission: [ERoles.CUSTOMER],
    title: "Home",
  },
]