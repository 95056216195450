import Websites from '../../pages/secure/dashboard/website/Websites'
import { CreateWebsite } from '../../pages/secure/dashboard/website/CreateWebsite'
import { EditWebsite } from '../../pages/secure/dashboard/website/EditWebsite'
import { ERoles } from '../../enums/ERoles'
import { IRoute } from '../../models/routes/IRoute'

export const WebsiteRoutes: IRoute[] = [
  {
    element: <Websites />,
    path: "websites",
    permission: [ERoles.ADMIN],
    title: "Websites",
    showInMenu: true,
  },
  {
    element: <CreateWebsite />,
    path: "websites/new",
    permission: [ERoles.ADMIN],
    title: "New Website",
  },
  {
    element: <EditWebsite />,
    path: "websites/edit/:id",
    permission: [ERoles.ADMIN],
    title: "Edit Website",
  },
]