import DashboardRoutesConfig from '../../../routes/DashboardRoutesConfig'
import { AuthContext } from '../../../hooks/useAuth'
import { AutoWidthCenter } from '../AutoWidthCenter'
import { Box, Button, ButtonGroup, Flex, Heading, HStack } from '@chakra-ui/react'
import { GetAllowedRoutes } from '../../../routes/RouteUtils'
import { IRoute } from '../../../models/routes/IRoute'
import { NavLink, useNavigate } from 'react-router-dom'
import { routePrefixDashboard } from '../../../ApplicationRouter'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

export const DashboardNavbar = () => {
  const { t } = useTranslation();
  const tokenContext = useContext(AuthContext);
  const isAuthenticated = tokenContext.isAuthenticated;
  let navigate = useNavigate();

  let allowedRoutes: IRoute[] = [];
  if (isAuthenticated) {
    allowedRoutes = GetAllowedRoutes(DashboardRoutesConfig).filter((route) => route.showInMenu);
  } else {
    navigate("/login", { replace: true });
  }

  return (
    <Box id="navbar" as="section"
      color="whiteAlpha.800"
      backgroundColor="bgPrimaryBlack"
    >
      <Box as="nav">
        <AutoWidthCenter>
          <Flex justify="space-between" px={6} py={3}>
            <HStack spacing="4">
              <Heading as="h1" variant="href" color="whiteAlpha.800" title={`${t("ApplicationName")} ${process.env.REACT_APP_VERSION}`} onClick={() => navigate(routePrefixDashboard)}>{t("ApplicationName").toUpperCase()}</Heading>
              <ButtonGroup variant="ghost" spacing="1">
                {allowedRoutes.map((route) => (
                  <Button as={NavLink} key={route.path} name={route.title} to={`${route.path}`}>{route.title}</Button>
                ))}
              </ButtonGroup>
              <Button as={NavLink} name="Signout" to="/signout">Signout</Button>
            </HStack>
          </Flex>
        </AutoWidthCenter>
      </Box>
    </Box>
  )
}