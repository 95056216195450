import { AiOutlineDelete } from 'react-icons/ai'
import { Button, Heading, IconButton, Spinner, Text, useToast } from '@chakra-ui/react'
import { DataView, IDataViewColumn } from '../../common/dataview/DataView'
import { FiEdit2 } from 'react-icons/fi'
import { GetCustomers, useDeleteCustomerMutation } from '../../../api/calls/customer/CustomerQueries'
import { ICustomer } from '../../../models/Customer'
import { NavLink } from 'react-router-dom'
import { useModal } from '../../../context/ModalContext'
import { useQuery, useQueryClient } from 'react-query'
import { useState } from 'react'

export default function CustomerList() {
  const { dispatch } = useModal();
  const toast = useToast();

  const [columns] = useState<IDataViewColumn[]>([
    { headerName: "FirstName", field: "firstName", flex: 0.5 },
    { headerName: "EmailAddress", field: 'email', flex: 1 },
    { headerName: "Username", field: 'userName', flex: 1 },
    { headerName: "Delay", field: 'sendingDelay', flex: 0.5 },
    { headerName: "Last activity", field: 'lastActivity', flex: 1, cellRenderer: 'renderdatetime' },
    { headerName: "Actions", cellRenderer: 'actions', flex: 0.5 },
  ]);
  const queryClient = useQueryClient();
  const handleDeleteCustomer = useDeleteCustomerMutation();
  const { data, isLoading } = useQuery(['customers'], GetCustomers);

  const handleDelete = (id: string) => {
    handleDeleteCustomer.mutate(id, {
      onSuccess() {
        queryClient.invalidateQueries(["customers"])
        dispatch({ type: 'close' })
        toast({
          title: "Customer Deleted",
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      },
      onError() {
        toast({
          title: "Error deleting customer",
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top"
        })
      }
    })
  }

  const renderCellAction = (tableData: any) => {
    const customer: ICustomer = tableData.data;
    return (
      <>
        <IconButton
          icon={<FiEdit2 fontSize="1.25rem" />}
          aria-label='Edit customer'
          as={NavLink}
          to={`edit/${customer.id}`}
        />
        <IconButton
          icon={<AiOutlineDelete fontSize="1.25rem" />}
          aria-label="Delete customer"
          color="red"
          onClick={() => dispatch({
            type: 'open', payload: {
              title: "Delete customer",
              bodyContent: <>
                <Text>Are you sure you want to delete the customer?</Text>
              </>,
              footerContent:
                <>
                  <Button mr={3} color="red" onClick={() => handleDelete(customer.id)}>Delete</Button>
                  <Button onClick={() => dispatch({ type: 'close' })}>Close</Button>
                </>
            }
          })}
        />
      </>
    )
  }

  if (isLoading) return <Spinner />
  if (!isLoading && !data) return <Heading as="h1">No customers found.</Heading>

  return (
    <DataView
      actionsRenderer={renderCellAction}
      columns={columns}
      data={data}
    />
  );
}