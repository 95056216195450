import Profile from '../../pages/secure/customer/Profile'
import { ERoles } from '../../enums/ERoles'
import { IRoute } from '../../models/routes/IRoute'

export const ProfileRoutes: IRoute[] = [
  {
    element: <Profile />,
    path: "p",
    permission: [ERoles.CUSTOMER],
    title: "Profile",
    showInMenu: true,
  },
]