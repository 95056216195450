import CustomerForm from '../../../../components/secure/customers/CustomerForm'
import WebsiteCustomerForm from '../../../../components/secure/customers/WebsiteCustomerForm'
import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack } from '@chakra-ui/react'
import { GetCustomer, GetWebsitesForCustomerQuery } from '../../../../api/calls/customer/CustomerQueries'
import { history } from '../../../../ApplicationRouter'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

export default function EditCustomer() {
  const { id } = useParams();
  const { data: customer } = useQuery(['customer', id], () => GetCustomer(id), { enabled: !!id });
  const { data: websitesData } = useQuery(['customer/websites', id], () => GetWebsitesForCustomerQuery(id), { enabled: !!id });

  return (
    <AutoWidthCenter>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">Edit customer</Heading>
          <Button onClick={() => history.back()}>Back</Button>
        </HStack>
        <Divider />
        {customer && <CustomerForm customer={customer} />}
        {websitesData && id && <WebsiteCustomerForm customerId={id} customersForWebsite={websitesData} />}
      </Box>
    </AutoWidthCenter>
  );
}