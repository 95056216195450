import { BaseProps, FormControl } from './FormControl'
import { ForwardedRef, forwardRef, useState } from 'react'
import { IconButton, Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react'
import { useField } from 'formik'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

export type PasswordControlProps = BaseProps & { inputProps?: InputProps };

export const PasswordControl: React.FC<PasswordControlProps> = forwardRef(
  (props: PasswordControlProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { name, label, inputProps, placeholder, ...rest } = props;
    const [field] = useField(name);
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    return (
      <FormControl name={name} label={label} {...rest}>
        <InputGroup>
          <Input type={show ? 'text' : 'password'} {...field} id={name} {...inputProps} ref={ref} />
          <InputRightElement width="4.5rem">
            <IconButton aria-label="" icon={show ? <ViewIcon /> : <ViewOffIcon />} onClick={handleClick} />
          </InputRightElement>
        </InputGroup>
      </FormControl>
    );
  }
);

export default PasswordControl;