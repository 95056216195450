import { AdminFormValues, IAdmin } from '../../../models/Admin'
import { Box, Button, Flex, useToast, VStack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { history } from '../../../ApplicationRouter'
import { InputControl, PasswordControl } from '../../common/formik-chakra'
import { useCreateAdminMutation, useEditAdminMutation } from '../../../api/calls/admin/AdminQueries'
import { useEffect, useState } from 'react'

interface Props {
  admin?: IAdmin
}

export default function AdminForm({ admin }: Props) {
  const [formValues, setFormValues] = useState(new AdminFormValues());
  const toast = useToast();
  const handleCreateAdmin = useCreateAdminMutation();
  const handleEditAdmin = useEditAdminMutation();

  useEffect(() => {
    if (admin) setFormValues(new AdminFormValues(admin));
  }, [admin]);

  const handleSubmit = async (values: AdminFormValues) => {
    if (admin) {
      handleEditAdmin.mutate({ id: admin.id, values: values }, {
        onSuccess() {
          history.back();
          toast({
            title: "Admin Edited",
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: "Error editing admin",
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      });
    } else {
      handleCreateAdmin.mutate(values, {
        onSuccess() {
          history.back();
          toast({
            title: "Admin created",
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: "Error creating admin",
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    }
  }

  return (
    <Flex align='center' justify="center">
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Box p="10" m="6" rounded="lg" maxW="80%" w="80%">
            <Form onSubmit={formik.handleSubmit}>
              <VStack>
                <InputControl name="email" label="Email" isDisabled={!!admin} />
                <InputControl name="userName" label="User name" isDisabled={!!admin} />
                {!admin && (
                  <>
                    <PasswordControl name="password" label="Password" isDisabled={!!admin} />
                  </>
                )}
                <InputControl name="firstName" label="First name" />
                <InputControl name="lastName" label="Last name" />
                <InputControl name="lastNamePrefix" label="Last name prefix" />

                <Box width="100%" pt="3">
                  <Button float="right" type="submit">Submit</Button>
                </Box>
              </VStack>
            </Form>
          </Box>
        )}
      </Formik>
    </Flex>
  );
}