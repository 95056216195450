import CustomerProfileForm from '../../../components/secure/customers/CustomerProfileForm'
import { AutoWidthCenter } from '../../../components/layout/AutoWidthCenter'
import { Box, Heading, HStack } from '@chakra-ui/react'
import { GetProfile } from '../../../api/calls/customer/CustomerQueries'
import { useQuery } from 'react-query'

export default function Profile() {
  const { data } = useQuery(['customer/profile'], GetProfile);
  return (
    <AutoWidthCenter>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">Profile</Heading>
        </HStack>
      </Box>
      {data && <CustomerProfileForm customer={data} />}
    </AutoWidthCenter>
  );
}