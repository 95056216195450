import { CreateDefaultContact } from '../../pages/secure/dashboard/contacts/CreateDefaultContact'
import { DefaultContacts } from '../../pages/secure/dashboard/contacts/DefaultContacts'
import { EditDefaultContact } from '../../pages/secure/dashboard/contacts/EditDefaultContact'
import { ERoles } from '../../enums/ERoles'
import { IRoute } from '../../models/routes/IRoute'

export const ContactRoutes: IRoute[] = [
  {
    element: <DefaultContacts />,
    path: "contacts/default",
    permission: [ERoles.ADMIN],
    title: "Default contacts",
    showInMenu: true,
  },
  {
    element: <CreateDefaultContact />,
    path: "contacts/default/new",
    permission: [ERoles.ADMIN],
    title: "New default contact",
  },
  {
    element: <EditDefaultContact />,
    path: "contacts/default/edit/:id",
    permission: [ERoles.ADMIN],
    title: "Edit default contact",
  }
]