import * as Yup from 'yup'
import { PasswordValidation } from '../../../utils/Validation'

export const CustomerProfileValidationSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().nullable(),
  lastNamePrefix: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  password: Yup.object().nullable().shape({
    currentPassword: Yup.string().required(),
    password: Yup.string().required().notOneOf([Yup.ref("currentPassword"), null], "New password can't be the same as the current password").matches(PasswordValidation, "Password must be at least 8 characters long, contain a lowercase letter, an uppercase letter, a number and a special character ($@!%*#?&></)(^|)."),
    confirmPassword: Yup.string().required().oneOf([Yup.ref("password"), null], "The entered passwords do not match.")
  })
})