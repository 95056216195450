import { AdminFormValues, AdminFormValuesWithId, IAdmin } from '../../../models/Admin'
import { apiClient } from '../../base/ApiClients'
import { useMutation } from 'react-query'

export const GetAdmin = async (id: any) => {
  const { data } = await apiClient.get<IAdmin>(`admins/${id}`);
  return data;
}

export const GetAdmins = async () => {
  const { data } = await apiClient.get<IAdmin[]>("admins");
  return data
}

const CreateAdmin = async (values: AdminFormValues) => {
  return await apiClient.post("admins", values);
}

export const useCreateAdminMutation = () => useMutation(CreateAdmin, {});

const EditAdmin = async (values: AdminFormValuesWithId) => {
  return await apiClient.put(`admins/${values.id}`, values.values);
}

export const useEditAdminMutation = () => useMutation(EditAdmin, {});

const DeleteAdmin = async (id: string) => {
  return await apiClient.delete(`admins/${id}`)
}

export const useDeleteAdminMutation = () => useMutation(DeleteAdmin, {});