import { ICustomer } from './Customer'

export interface IDefaultContact {
  id: string
  name: string;
  email: string;
  phoneNumber: string;
}

export class DefaultContactFormValues {
  name: string = "";
  email: string = "";
  phoneNumber: string = "";
  constructor(init?: IDefaultContact) {
    Object.assign(this, init);
  }
}

export type DefaultContactFormValuesWithId = {
  id: string;
  values: DefaultContactFormValues;
}

export interface IContactsForWebsite {
  allDefaultContacts: IDefaultContact[];
  defaultContacts: string[];
  allCustomers: ICustomer[];
  customers: string[];
}

export class ContactsForWebsiteFormValues {
  defaultContacts: string[] = [];
  customers: string[] = [];
  constructor(init?: IContactsForWebsite) {
    if (init?.defaultContacts) this.defaultContacts = init?.defaultContacts
    if (init?.customers) this.customers = init?.customers
  }
}

export type ContactsForWebsiteFormValuesWithId = {
  id: string;
  values: ContactsForWebsiteFormValues;
}
