import { apiClient } from '../../base/ApiClients'
import { ForgotPasswordFormValues, ResetPasswordFormValues, VerifyPasswordResetValues } from '../../../models/Password'
import { useMutation } from 'react-query'

const ForgotPassword = async (values: ForgotPasswordFormValues) => {
  return await apiClient.post("password/forgot", values);
}

export const useForgotPasswordMutation = () => useMutation(ForgotPassword, {});

const VerifyToken = async (values: VerifyPasswordResetValues) => {
  return await apiClient.post<boolean>("password/verify-password-reset-token", values);
}

export const useVerifyTokenMutation = () => useMutation(VerifyToken, {});

const ResetPassword = async (values: ResetPasswordFormValues) => {
  return await apiClient.post("password/reset", values);
}

export const useResetPasswordMutation = () => useMutation(ResetPassword, {});