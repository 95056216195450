import { Box, Button, Flex, Heading, useToast, VStack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { history } from '../../ApplicationRouter'
import { PasswordControl } from '../../components/common/formik-chakra'
import { ResetPasswordFormValues, VerifyTokenDto } from '../../models/Password'
import { ResetPasswordValidationSchema } from './ResetPassword.Validation'
import { useEffect, useState } from 'react'
import { useResetPasswordMutation } from '../../api/calls/user/PasswordQueries'

interface Props {
  tokenDto: VerifyTokenDto
}

export default function ResetPasswordForm({ tokenDto }: Props) {
  const [formValues, setFormValues] = useState(new ResetPasswordFormValues())
  const handleResetPassword = useResetPasswordMutation();
  const toast = useToast();

  useEffect(() => {
    setFormValues(new ResetPasswordFormValues(tokenDto));
  }, [tokenDto])

  const handleSubmit = async (values: ResetPasswordFormValues) => {
    handleResetPassword.mutate(values, {
      onSuccess() {
        toast({
          title: "Password changed",
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
        history.push("/");
      },
      onError() {
        toast({
          title: "Error changing password",
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top"
        })
      }
    });
  }

  return (
    <Flex align="center" justify="center">
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={ResetPasswordValidationSchema}
      >
        {(formik) => (
          <Box p="10" m="6" rounded="lg" maxW="80%" w="80%">
            <Heading size="lg" mb="3">Reset password</Heading>
            <Form onSubmit={formik.handleSubmit}>
              <VStack>
                <PasswordControl name="password" label="New password" />
                <PasswordControl name="confirmPassword" label="Confirm new password" />
              </VStack>
              <Box width="100%" pt="3">
                <Button float="right" type="submit">Submit</Button>
              </Box>
            </Form>
          </Box>
        )}
      </Formik>
    </Flex>
  );
}