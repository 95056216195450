import { Box, Button, Flex, useToast, VStack } from '@chakra-ui/react'
import { CustomerFormValues, ICustomer } from '../../../models/Customer'
import { CustomerValidationSchema } from './Customer.Validation'
import { Form, Formik } from 'formik'
import { history } from '../../../ApplicationRouter'
import { InputControl, NumberControl, PasswordControl } from '../../common/formik-chakra'
import { useCreateCustomerMutation, useEditCustomerMutation } from '../../../api/calls/customer/CustomerQueries'
import { useEffect, useState } from 'react'

interface Props {
  customer?: ICustomer;
}

export default function CustomerForm({ customer }: Props) {

  const [formValues, setFormValues] = useState(new CustomerFormValues());
  const toast = useToast();
  const handleCreateCustomer = useCreateCustomerMutation();
  const handleEditCustomer = useEditCustomerMutation();

  useEffect(() => {
    if (customer) setFormValues(new CustomerFormValues(customer));
  }, [customer]);

  const handleSubmit = async (values: CustomerFormValues) => {
    if (customer) {
      handleEditCustomer.mutate({ id: customer.id, values: values }, {
        onSuccess() {
          history.back();
          toast({
            title: "Customer Edited",
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: "Error editing customer",
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      });
    } else {
      handleCreateCustomer.mutate(values, {
        onSuccess() {
          history.back();
          toast({
            title: "Customer created",
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: "Error creating customer",
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    }
  }

  return (
    <Flex align='center' justify="center">
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={CustomerValidationSchema}
      >
        {(formik) => (
          <Box p="10" m="6" rounded="lg" maxW="80%" w="80%">
            <Form onSubmit={formik.handleSubmit}>
              <VStack>
                <InputControl name="email" label="Email" isDisabled={!!customer} isRequired />
                <InputControl name="userName" label="Username" isDisabled={!!customer} isRequired />
                {!customer && (
                  <>
                    <PasswordControl name="password" label="Password" isDisabled={!!customer} isRequired={!customer} />
                  </>
                )}
                <InputControl name="firstName" label="First name" isRequired />
                <InputControl name="lastName" label="Last name" />
                <InputControl name="lastNamePrefix" label="Last name prefix" />
                <InputControl name="phone" label="Phone number starting with country-code (no ' ' or '+' or '-')" />
                <NumberControl name="sendingDelay" label="Delay in minutes before a message should be send to this customer" isRequired />

                <Box width="100%" pt="3">
                  <Button float="right" type="submit">Submit</Button>
                </Box>
              </VStack>
            </Form>
          </Box>
        )}
      </Formik>
    </Flex>
  );
}