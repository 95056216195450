import { Box, Button, Heading, HStack, useToast } from '@chakra-ui/react'
import { CheckboxContainer, ICheckboxItem } from '../../common/formik-chakra'
import { Form, Formik } from 'formik'
import { IWebsitesForCustomer, WebsitesForCustomerFormValues } from '../../../models/Customer'
import { useEffect, useState } from 'react'
import { useUpdateWebsitesForCustomerMutation } from '../../../api/calls/customer/CustomerQueries'

interface Props {
  customerId: string;
  customersForWebsite: IWebsitesForCustomer;
}

export default function WebsiteCustomerForm({ customerId, customersForWebsite }: Props) {
  const [formValues, setFormValues] = useState(new WebsitesForCustomerFormValues());
  const [websites, setWebsites] = useState<ICheckboxItem[]>([]);
  const handleUpdateWebsites = useUpdateWebsitesForCustomerMutation();
  const toast = useToast();

  useEffect(() => {
    setFormValues(customersForWebsite);
    const websites = customersForWebsite.allWebsites.map((website) => {
      const item: ICheckboxItem = { key: website.id, name: `${website.name} - ${website.url}`, value: website.id };
      return item;
    });
    setWebsites(websites);
  }, [customersForWebsite]);

  const handleSubmit = (values: WebsitesForCustomerFormValues) => {
    handleUpdateWebsites.mutate({ id: customerId, values: values }, {
      onSuccess() {
        toast({
          title: "Linked websites updated",
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      },
      onError() {
        toast({
          title: "Error updating linked websites",
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top"
        })
      }
    })
  }

  return (
    <>
      <Heading size="lg">Edit linked websites for customer</Heading>
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Box p="10" m="6" rounded="lg" maxW="80%" w="80%">
            <Form onSubmit={formik.handleSubmit}>
              <HStack pb="5" justifyContent="space-between">
                <CheckboxContainer label="Linked websites" name="websites" options={websites} />
              </HStack>
              <Box width="100%" pt="3">
                <Button float="right" type="submit">Submit</Button>
              </Box>
            </Form>
          </Box>
        )}
      </Formik>
    </>
  );
}