import { IWebsite } from './website/IWebsite'

export interface ICustomer {
  id: string;
  customerDetailsId: string;
  email: string;
  firstName: string;
  userName: string;
  lastNamePrefix?: string;
  lastName?: boolean;
  phone?: string;
  sendingDelay: number;
}

export class CustomerFormValues {
  id?: string | undefined;
  email: string = '';
  userName: string = '';
  firstName: string = '';
  lastName: string = '';
  lastNamePrefix: string = '';
  sendingDelay: number = 0;
  phone?: string = undefined;

  constructor(init?: ICustomer) {
    Object.assign(this, init);
  }
}

export type CustomerFormValuesWithId = {
  id: string;
  values: CustomerFormValues;
}

export interface IWebsitesForCustomer {
  allWebsites: IWebsite[];
  websites: string[];
}

export class WebsitesForCustomerFormValues {
  websites: string[] = [];
  constructor(init?: IWebsitesForCustomer) {
    if (init?.websites) this.websites = init?.websites
  }
}

export type WebsitesForCustomerFormValuesWithId = {
  id: string;
  values: WebsitesForCustomerFormValues;
}

export interface ISimpleCustomer {
  id: string;
  email: string;
  firstName: string;
  userName: string;
  lastNamePrefix?: string;
  lastName?: string;
  phone?: string;
  password?: IChangePassword;
}

export class CustomerSimpleFormValues {
  id?: string | undefined;
  firstName: string = '';
  lastName?: string = '';
  lastNamePrefix?: string = '';
  phone?: string = undefined;
  password?: IChangePassword = undefined;

  constructor(init?: ISimpleCustomer) {
    Object.assign(this, init);
  }
}

export type CustomerSimpleFormValuesWithId = {
  id: string;
  values: CustomerSimpleFormValues;
}

export interface IChangePassword {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}