import ForgotPassword from '../../pages/login/ForgotPassword'
import ResetPassword from '../../pages/login/ResetPassword'
import { Login } from '../../pages/login/Login'
import { RouteObject } from 'react-router-dom'
import { Signout } from '../../pages/login/Signout'

export const PublicRoutes: RouteObject[] = [
  {
    element: <Login />,
    path: "/",
    index: true
  },
  {
    element: <Login />,
    path: "/authentication/login"
  },
  {
    element: <Login />,
    path: "/login"
  },
  {
    element: <Signout />,
    path: "/signout"
  },
  {
    element: <ForgotPassword />,
    path: "forgot-password"
  },
  {
    element: <ResetPassword />,
    path: "reset-password"
  }
]