import ResetPasswordForm from './ResetPasswordForm'
import { AutoWidthCenter } from '../../components/layout/AutoWidthCenter'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useVerifyTokenMutation } from '../../api/calls/user/PasswordQueries'

export default function ResetPassword() {
  const params = new URLSearchParams(useLocation().search);
  const token = params.get("token") ?? "";
  const userName = params.get("username") ?? "";
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleVerifyToken = useVerifyTokenMutation();

  useEffect(() => {
    setLoading(true);
    const verify = async () => {
      if (!token || !userName) {
        setValid(false);
      } else {
        handleVerifyToken.mutate({ token: token, userName: userName }, {
          onSuccess(data, variables, context) {
            if (data.data) setValid(true);
            else setValid(false);
            setLoading(false);
          },
          onError() {
            setValid(false);
            setLoading(false);
          },
        })
      }
    }
    verify();
  }, [userName, token]);

  if (loading) return <></>

  return (
    <AutoWidthCenter>
      {valid && userName && token && <ResetPasswordForm tokenDto={{ token: token, userName: userName }} />}
    </AutoWidthCenter>
  );
}