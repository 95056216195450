import AdminList from '../../../../components/secure/admins/AdminList'
import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Button, Heading, HStack } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

export default function Admins() {
  return (
    <AutoWidthCenter>
      <HStack pb="5" mt="5" justifyContent="space-between">
        <Heading as="h1">Admins</Heading>
        <Button as={NavLink} to="new">New admin</Button>
      </HStack>
      <AdminList />
    </AutoWidthCenter>
  );
}