import axios from 'axios'
import { AuthContext } from '../../hooks/useAuth'
import { Button, Flex, Heading, Stack, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { IUserFormLoginValues } from '../../models/user'
import { Navigate, useNavigate } from 'react-router-dom'
import { removeSurroundingQuotes } from '../../utils/General'
import { TextInput } from '../../components/common/form/TextInput'
import { useContext, useEffect } from 'react'
import { useLogin } from '../../api/calls/user/useLogin'
import { useTranslation } from 'react-i18next'

export const LoginForm = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const handleLogin = useLogin();
  const tokenContext = useContext(AuthContext);
  const isAuthenticated = tokenContext.isAuthenticated;

  // Trigger navigation after role chagne.
  useEffect(() => {
    if (tokenContext.token.length > 0) {
      if (!tokenContext.hasRole()) {
        tokenContext.signOut();
      } else {
        let redirect = tokenContext.redirectToRole();
        navigate(redirect);
      }
    }
  }, [tokenContext.role])

  const handleSubmit = async (values: IUserFormLoginValues) => {
    handleLogin.mutate(values, {
      // Deze success en error worden alleen getriggered in dit component
      // Kan handig zijn als je dezelfde functie op meerdere plekken gebruikt maar bij succes een ander actie wilt uitvoere
      // Denk aan, route naar een andere pagina, toast tonen met verschillende texten erin
      onSuccess(data, variables, context) {
        const success = data.status === 200;
        const responseText = data.data;
        if (success) {
          const responseBody = removeSurroundingQuotes(responseText);
          tokenContext.setToken(responseBody);
        }
      },
      onError: (error: unknown, variables: IUserFormLoginValues, context: unknown) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 401) {
            //Het zou mooi zijn als we de error kunnen meegeven vanuit de backend als een soort key: Error_Login
            //Deze key zetten we in het translation bestand en daar koppelen we een leesbare omschrijving aan
            //vervolgens hoeven we alleen maar de message (wat dus de key van de error is) te tonen in een toast of te setten in state.
            //Dan krijgen we op die manier redelijk eenvoudig custom error messages vanuit de backend
            toast({
              title: error.message,
              description: error.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top"
            })
          }
        }
      }
    })
  }

  if (tokenContext) {
    return (isAuthenticated ? (
      <Navigate to={tokenContext.redirectToRole()} />
    ) : (
      <>
        <Heading as="h1">Login</Heading>
        <Formik
          initialValues={{ password: "", username: "" }}
          onSubmit={handleSubmit}
        >
          {(formik: any) => (
            <Form onSubmit={formik.handleSubmit}>
              <Flex justify="flex-end">
                <a className='underline-h' href='/forgot-password'>Forgot password</a>
              </Flex>
              <Stack mt="3">
                <Field
                  component={TextInput}
                  name="username"
                />
                <Field
                  component={TextInput}
                  name="password"
                  chakraProps={{
                    type: "password"
                  }}
                />
                <Button isLoading={handleLogin.isLoading} type="submit">{t("Login")}</Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </>
    ))
  } else {
    return null
  }
}
