import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack } from '@chakra-ui/react'
import { GetContactsForWebsiteQuery, GetWebsiteQuery } from '../../../../api/calls/website/WebsiteQueries'
import { history } from '../../../../ApplicationRouter'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { WebsiteContactForm } from '../../../../components/secure/website/WebsiteContactForm'
import { WebsiteForm } from '../../../../components/secure/website/WebsiteForm'

export const EditWebsite = () => {
  let { id } = useParams();

  const { data: website } = useQuery(['website', id], () => GetWebsiteQuery(id), { enabled: !!id });
  const { data: contactData } = useQuery(['website/contacts', id], () => GetContactsForWebsiteQuery(id), { enabled: !!id });

  return (
    <AutoWidthCenter>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">Edit website</Heading>
          <Button onClick={() => history.back()}>Back</Button>
        </HStack>
        <Divider />
        {website && <WebsiteForm website={website} />}
        <Divider />
        {contactData && id && <WebsiteContactForm websiteId={id} contactsForWebsite={contactData} />}
      </Box>
    </AutoWidthCenter>
  )
}