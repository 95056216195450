import { AutoWidthCenter } from '../../../components/layout/AutoWidthCenter'
import { Box, Divider, Heading } from '@chakra-ui/react'
import { CustomerWebsiteStatistics } from '../../../components/secure/statistic/CustomerWebsiteStatistics'
import { ERoles } from '../../../enums/ERoles'
import { RenderGuard } from '../../../components/common/guards/RenderGuard'

export const Home = () => {
  return (
    <Box pt="5">
      <AutoWidthCenter>
        <Heading as="h1">Dashboard</Heading>
        <Divider />
        <RenderGuard roles={ERoles.CUSTOMER}>
          <CustomerWebsiteStatistics />
        </RenderGuard>
      </AutoWidthCenter>
    </Box>
  )
}