import { BaseProps, FormControl } from './FormControl'
import { Checkbox, CheckboxProps } from '@chakra-ui/react'
import { forwardRef } from 'react'
import { useField } from 'formik'

export type CheckboxSingleProps = BaseProps & {
  checkBoxProps?: CheckboxProps;
  children?: React.ReactNode;
};

export const CheckboxSingleControl: React.FC<CheckboxSingleProps> = forwardRef(
  (props: CheckboxSingleProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { name, label, children, checkBoxProps, ...rest } = props;
    const [field, { error, touched }] = useField(name);
    const isChecked = field.value;

    return (
      <FormControl name={name} {...rest}>
        <Checkbox
          {...field}
          id={name}
          isInvalid={!!error && touched}
          isChecked={isChecked}
          ref={ref}
          {...checkBoxProps}
        >
          {label}
          {children}
        </Checkbox>
      </FormControl>
    );
  }
);